import React, { FunctionComponent, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useLiveMeasurements } from "scenes/site/hooks";

import {
  SiteConfig_siteConfig_tradingConf,
} from "graphql/generated";
import {
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Td,
} from "@chakra-ui/react";

import { JSONArray, Prediction, Plc } from "./types";

import GroupHeading from "./GroupHeading";
import RowOfValues from "./RowOfValues";
import ActivityIndicator from "./ActivityIndicator";

import "./trading.scss";


interface IProps {
  siteId: string;
  tradingConf: SiteConfig_siteConfig_tradingConf;
  cellWidth?: number;
  cellPaddingX?: number;
}


const Trading: FunctionComponent<IProps> = ({
  siteId,
  cellWidth = 35,
  cellPaddingX = 0,
  tradingConf,
}) => {
  const intl = useIntl();
  const { data } = useLiveMeasurements(siteId, "trading");
  const trading_data = data.trading;
  const prediction: Prediction = trading_data?.prediction;
  const { data: plcData } = useLiveMeasurements(siteId, "plc");
  const plc: Plc = plcData.plc;
  const e_prices: {[key: string]: JSONArray} = trading_data?.e_prices;
  const g_prices: {[key: string]: JSONArray} = trading_data?.g_prices;
  const hoursColor = useColorModeValue("white", "gray.950");
  const hourHighlightColor = useColorModeValue("gray.950", "white");
  const nowHour: number = new Date().getHours();
  const nowSummerTime: boolean = new Date().getTimezoneOffset() !== new Date(2000, 0, 1).getTimezoneOffset();
  const nowHourIndex = useMemo<number>(() => {
    if (nowHour >= 2) {
      if (prediction?.hours?.length === 48) return nowHour;
      if (prediction?.hours?.length === 49) {
        if (nowHour === 2) return nowSummerTime ? 2 : 3;
        if (nowHour > 2) return nowHour + 1;
      }
      if (prediction?.hours?.length === 47) {
        if (nowHour > 2) return nowHour -1;
      }
    }
    return nowHour;
  }, [nowHour, nowSummerTime, prediction?.hours]);

  const commonRowProps = useMemo(() => ({
    cellWidth,
    cellPaddingX,
    nowHourIndex,
  }), [cellWidth, cellPaddingX, nowHourIndex]);

  const goodBadColors = useMemo(() => ({
    // colors: ["#3ab860", "#E309"],
    colors: ["#3ab860", "transparent"],
  }), []);

  const badGoodColors = useMemo(() => ({
    // colors: ["#E309", "#3ab860"],
    colors: ["transparent", "#3ab860"],
  }), []);

  const value = useCallback((val: number | boolean): number | string => {
    if (val === true || val === false) return val ? "Y" : "N";
    if (val === undefined) return "-";
    return Math.round(val);
  }, [])

  const live_value = useCallback((val: number | boolean): number | string => {
    return `(${value(val)})`
  }, [value]);

  const unit = useCallback((unit: string): string => {
    return ` [${unit}]`
  }, [])

  return (
    <>
      <Table variant="unstyled" size="sm" mb={10}>
        <Thead>
          <Tr bg="enposol.500" color={hoursColor} fontWeight="bold">
            <Td colSpan={1} position="relative">
              <ActivityIndicator data={plc} position="absolute" left={0} top={0} />
              {intl.formatMessage({
                id: "site__peak_shaving__net",
                defaultMessage: "Production",
              })}
            </Td>
            <Td colSpan={1}>
              {intl.formatMessage({
                id: "site__peak_shaving__grid",
                defaultMessage: "Grid",
              })}
            </Td>
            <Td colSpan={4}>
              {intl.formatMessage({
                id: "site__peak_shaving__battery",
                defaultMessage: "Battery",
              })}
            </Td>
            <Td colSpan={2}>
              {intl.formatMessage({
                id: "site__peak_shaving__pv",
                defaultMessage: "PV",
              })}
            </Td>
            <Td colSpan={1}>
              {intl.formatMessage({
                id: "site__peak_shaving__chp",
                defaultMessage: "CHP",
              })}
            </Td>
            <Td colSpan={2}>
              {intl.formatMessage({
                id: "site__peak_shaving__heating",
                defaultMessage: "Heating",
              })}
            </Td>
            <Td colSpan={2}>
              {intl.formatMessage({
                id: "site__peak_shaving__furnace",
                defaultMessage: "Furnace",
              })}
            </Td>
          </Tr>
          <Tr bg="enposol.500" color={hoursColor}>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving___net_balance",
                defaultMessage: "Bilance",
              })}
              {unit("kW")}
            </Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__grid_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}
            </Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__battery_soc",
                defaultMessage: "SoC",
              })}
              {unit("%")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__battery_discharge",
                defaultMessage: "Discharge",
              })}
              {unit("kW")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__battery_control",
                defaultMessage: "Control",
              })}
            </Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__battery_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__pv_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__pv_power_throttled_to",
                defaultMessage: "Throttled to",
              })}
              {unit("%")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__chp_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__heating_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__heating_temperature",
                defaultMessage: "Temperature",
              })}
              {unit("°C")}</Td>
            <Td>
              {intl.formatMessage({
                id: "site__peak_shaving__furnace_active_power",
                defaultMessage: "Power",
              })}
              {unit("kW")}</Td>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td fontSize="1xl">{value(plc?.site.live_net_energy)}</Td>
            <Td fontSize="1xl">{value(plc?.grid.live_active_power)}</Td>
            <Td fontSize="1xl">{value(plc?.battery.live_soc)}</Td>
            <Td fontSize="1xl"><b>{value(plc?.battery.discharge)}</b> {live_value(plc?.battery.live_discharge)}</Td>
            <Td fontSize="1xl"><b>{value(plc?.battery.control)}</b> {live_value(plc?.battery.live_control)}</Td>
            <Td fontSize="1xl">{value(plc?.battery.live_active_power)}</Td>
            <Td fontSize="1xl">{live_value(plc?.pv.live_active_power)}</Td>
            <Td fontSize="1xl"><b>{value(plc?.pv.power_throttle)}</b> {live_value(plc?.pv.live_power_throttle)}</Td>
            <Td fontSize="1xl"><b>{value(plc?.chp.active_power)}</b></Td>
            <Td fontSize="1xl"><b>{value(plc?.heating.active_power)}</b></Td>
            <Td fontSize="1xl">{value(plc?.heating.live_temperature)}</Td>
            <Td fontSize="1xl">{live_value(plc?.furnace.live_active_power)}</Td>
          </Tr>
        </Tbody>
      </Table>

    {prediction && <>
      <Table variant="unstyled" size="sm" mb={10}>
        <Thead>
          <Tr bg="enposol.500" color={hoursColor} position="sticky" top={0} zIndex={1}>
            <Th bg="enposol.500" position="sticky" left={0}>
              <ActivityIndicator data={prediction} position="absolute" left={0} top={0} />
              {intl.formatMessage({
                id: "site__trading__hour",
                defaultMessage: "Hour",
              })}
            </Th>
            { prediction.hours.map((hour, i) => <>
              <Th
                bg={i === nowHourIndex ? hourHighlightColor : undefined}
                minWidth={`${cellWidth}px`}
                px={`${cellPaddingX}px`}
                textAlign="right"
                fontSize="x-small"
                title={`${i % 24}:00 - ${i % 24}:59`}
              >
                {hour % 24}:
              </Th>
            </> ) }
          </Tr>
        </Thead>
        <Tbody>
            <GroupHeading>
              {intl.formatMessage({
                id: "site__trading__automat__heading",
                defaultMessage: "Automat",
              })}
            </GroupHeading>
            <RowOfValues {...commonRowProps} {...badGoodColors} key="dc" label={intl.formatMessage({id: "site__trading__row__discharge_plan", defaultMessage: "discharge plan"})} units="x" values={prediction.discharge_plan} />
            <RowOfValues {...commonRowProps} key="cg" label={intl.formatMessage({id: "site__trading__row__no_charge", defaultMessage: "no charging"})} values={prediction.no_charge} withChart={false} />
            <RowOfValues {...commonRowProps} key="dg" label={intl.formatMessage({id: "site__trading__row__no_discharge", defaultMessage: "no discharging"})} values={prediction.no_discharge} withChart={false} />
            <RowOfValues {...commonRowProps} key="pv" label={intl.formatMessage({id: "site__trading__row__no_pv", defaultMessage: "no PV"})} values={prediction.no_pv} withChart={false} />
            <RowOfValues {...commonRowProps} key="ch" label={intl.formatMessage({id: "site__trading__row__no_chp", defaultMessage: "no CHP"})} values={prediction.no_chp} withChart={false} />
            <GroupHeading>
              {intl.formatMessage({
                id: "site__trading__prediction__heading",
                defaultMessage: "Prediction",
              })}
            </GroupHeading>
            <RowOfValues {...commonRowProps} {...goodBadColors} key="cs" label={intl.formatMessage({id: "site__trading__row__clouds", defaultMessage: "clouds"})} units="%" values={prediction.weather.map(_ => (1 - _) * 100)} />
            <RowOfValues {...commonRowProps} {...badGoodColors} key="pp" label={intl.formatMessage({id: "site__trading__row__production__pv", defaultMessage: "production PV"})} units="kWh" values={prediction.pv_production} />
            <RowOfValues {...commonRowProps} {...badGoodColors} key="pc" label={intl.formatMessage({id: "site__trading__row__production__chp", defaultMessage: "production CHP"})} units="kWh" values={prediction.chp_production} />
            <RowOfValues {...commonRowProps} key="ga" label={intl.formatMessage({id: "site__trading__row__advantage_gas_to_consume", defaultMessage: "→to consume"})} values={prediction.consumption_bound_advantage_gas} withChart={false} />
            <RowOfValues {...commonRowProps} key="ga" label={intl.formatMessage({id: "site__trading__row__advantage_gas_to_sell", defaultMessage: "→to sell"})} values={prediction.market_bound_advantage_gas} withChart={false} />
            <RowOfValues {...commonRowProps} {...goodBadColors} key="et" label={intl.formatMessage({id: "site__trading__row__carbon_emissions", defaultMessage: "CO<sub>2</sub> emissions"})} units="kg/kWh" values={prediction.carbon_emissions} multiplier={0.001} />
            <RowOfValues {...commonRowProps} key="ep" label={intl.formatMessage({id: "site__trading__row__carbon_emissions_pv", defaultMessage: "→PV"})} units="kg/kWh" values={prediction.carbon_emissions_pv} multiplier={0.001} withChart={false} />
            <RowOfValues {...commonRowProps} key="ec" label={intl.formatMessage({id: "site__trading__row__carbon_emissions_chp", defaultMessage: "→CHP"})} units="kg/kWh" values={prediction.carbon_emissions_chp} multiplier={0.001} withChart={false} />
            <RowOfValues {...commonRowProps} {...badGoodColors} key="es" label={intl.formatMessage({id: "site__trading__row__carbon_savings", defaultMessage: "CO<sub>2</sub> savings"})} units="kg/kWh" values={prediction.carbon_savings} multiplier={0.001} />
            <RowOfValues {...commonRowProps} {...goodBadColors} key="co" label={intl.formatMessage({id: "site__trading__row__consumption", defaultMessage: "consumption"})} units="kWh" values={prediction.consumption} />
            <RowOfValues {...commonRowProps} key="cf" label={intl.formatMessage({id: "site__trading__row__consumption_furnace", defaultMessage: "→furnace"})} units="kWh" values={prediction.consumption_furnace} withChart={false} />
            <RowOfValues {...commonRowProps} {...goodBadColors} key="so" label={intl.formatMessage({id: "site__trading__row__needed_soc", defaultMessage: "needed SoC"})} units="%" values={prediction.soc} />
            <RowOfValues {...commonRowProps} {...badGoodColors} key="ex" label={intl.formatMessage({id: "site__trading__row__excess_power", defaultMessage: "excess power"})} units="kWh" values={prediction.excess_power} />
            <GroupHeading>
              {intl.formatMessage({
                id: "site__trading__profit__heading",
                defaultMessage: "Profit",
              })}
            </GroupHeading>
            <RowOfValues {...commonRowProps} {...badGoodColors} key="s1" label={intl.formatMessage({id: "site__trading__row__scenario_1", defaultMessage: "scenario 1"})} units="€" values={prediction.profit_without_charging} />
            <RowOfValues {...commonRowProps} {...badGoodColors} key="s2" label={intl.formatMessage({id: "site__trading__row__scenario_2", defaultMessage: "scenario 2"})} units="€" values={prediction.profit_trading} />

          {e_prices && <>
            <GroupHeading>
              {intl.formatMessage({
                id: "site__trading__electricity_prices__heading",
                defaultMessage: "Electricity Prices",
              })}
            </GroupHeading>
            <RowOfValues {...commonRowProps} {...goodBadColors} key="ey" label={intl.formatMessage({id: "site__trading__row__buy", defaultMessage: "buy"})} units="€/MWh" values={e_prices.buy_kwh} />
            <RowOfValues {...commonRowProps} key="eb" label={intl.formatMessage({id: "site__trading__row__base", defaultMessage: "base"})} units="€/MWh" values={e_prices.base_kwh} withChart={false} />
            <RowOfValues {...commonRowProps} key="es" label={intl.formatMessage({id: "site__trading__row__sell", defaultMessage: "sell"})} units="€/MWh" values={e_prices.sell_kwh} withChart={false} />
          </>}

          {g_prices && <>
            <GroupHeading>
              {intl.formatMessage({
                id: "site__trading__gas_prices__heading",
                defaultMessage: "Gas Prices",
              })}
            </GroupHeading>
            <RowOfValues {...commonRowProps} {...goodBadColors} key="gy" label={intl.formatMessage({id: "site__trading__row__buy", defaultMessage: "buy"})} units="€/MWh" values={g_prices.buy_kwh} />
            <RowOfValues {...commonRowProps} key="gb" label={intl.formatMessage({id: "site__trading__row__base", defaultMessage: "base"})} units="€/MWh" values={g_prices.base_kwh} withChart={false} />
            <RowOfValues {...commonRowProps} key="ge" label={intl.formatMessage({id: "site__trading__row__in_electricity", defaultMessage: "→electricity"})} units="€/MWh" values={g_prices.electricity_kwh} withChart={false} />
            <RowOfValues {...commonRowProps} key="gh" label={intl.formatMessage({id: "site__trading__row__in_heat", defaultMessage: "→heat"})} units="€/MWh" values={g_prices.heat_kwh} withChart={false} />
          </>}
        </Tbody>
      </Table>
    </>}
    </>
  );
};

export default Trading;
