import { gql } from "@apollo/client";

export const LIST_SITES_QUERY = gql`
  query ListSites {
    listSites {
      id
      name
    }
  }
`;

export const SESSION_STATE_QUERY = gql`
  query SessionState {
    sessionState {
      user {
        id
        token
        username
        email
        role
        organization {
          id
          name
        }
        homesiteId
        preferences {
          ui__dark_mode
          ui__locale
        }
      }
    }
  }
`;

export const SITE_CONFIG_QUERY = gql`
  query SiteConfig($siteId: ID!) {
    siteConfig(siteId: $siteId) {
      id
      name
      timezone
      navTabPriority
      navUiVersion
      manualControlConf {
        activePowerMax
        reactivePowerMax
      }
      dashboardConf {
        gridWidth
        gridHeight
        components {
          cardId
          type
          id
          label
          grouping
          flip
          x
          y
          chartData {
            sources {
              uri
              lang
            }
          }
          uiVersion
        }
        connections {
          id
          componentA {
            id
            anchor
          }
          componentB {
            id
            anchor
          }
          defaultFlow
        }
      }
      modesConf {
        uiVersion
        manualUiVersion
        peakShavingUiVersion
        timedPeakUiVersion
        loadLevelingUiVersion
        offgridUiVersion
      }
      batteryConf {
        racks {
          id
        }
        modules {
          id
        }
        cells {
          id
        }
        avgTmpChart {
          sources {
            uri
            lang
          }
        }
        socChart {
          sources {
            uri
            lang
          }
        }
        uiRackColumnLimit
        uiModuleColumnLimit
        uiVersion
      }
      converterConf {
        converters {
          id
        }
        chartA {
          sources {
            uri
            lang
          }
        }
        chartB {
          sources {
            uri
            lang
          }
        }
        uiVersion
      }
      upsConf {
        ups {
          id
        }
        uiVersion
      }
      hvacConf {
        hvac {
          id
          x
          y
        }
        gridWidth
        gridHeight
        uiVersion
      }
      chargersConf {
        chargers {
          id
          title
        }
        uiVersion
        chart {
          sources {
            uri
            lang
          }
        }
      }
      gridConf {
        uiVersion
        chartA {
          sources {
            uri
            lang
          }
        }
        chartB {
          sources {
            uri
            lang
          }
        }
      }
      gensetsConf {
        gensets {
          id
        }
        uiVersion
      }
      furnacesConf {
        furnaces {
          id
        }
        uiVersion
      }
      chartsConf {
        uiVersion
        dashboard {
          sources {
            uri
            lang
          }
        }
      }
      notificationsConf {
        uiVersion
      }
      pvConf {
        inverters {
          id
          x
          y
        }
        gridWidth
        gridHeight
        uiVersion
        stringConf {
          panels {
            id
            x
            y
          }
          gridWidth
          gridHeight
          uiVersion
        }
      }
      factoryConf {
        factories {
          id
        }
      }
      atsConf {
        ats {
          id
        }
        uiVersion
      }
      tradingConf {
        consumers {
          id
          title
        }
      }
      exportConf {
        exports {
          subject
          frequency
          id
          label
          grouping
          disabled
        }
        uiVersion
      }
      settingsConf {
        uiVersion
        tabPriority
        generalTabUiVersion
        primaryTabUiVersion
        secondaryTabUiVersion
        peakShavingTabUiVersion
        peakShavingAlgorithmTabUiVersion
        timedPeakTabUiVersion
        offgridTabUiVersion
        powerFactorTabUiVersion
        loadLevelingTabUiVersion
        consumptionTabUiVersion
        tradingTabUiVersion
      }
    }
  }
`;

export const SITE_NOTIFICATIONS_QUERY = gql`
  query SiteNotifications($siteId: ID!, $onlyActive: Boolean!) {
    siteNotifications(siteId: $siteId, onlyActive: $onlyActive) {
      id
      alarmType
      conditionId
      text
      createTimestamp
      acknowledgeTimestamp
      expireTimestamp
    }
  }
`;

// export const GET_MODES_STATE = gql`
//   query GetModesState($siteId: ID!) {
//     getModesState(siteId: $siteId) {
//       manual {
//         on
//         enabled
//       }
//       peakShaving {
//         on
//         enabled
//       }
//       loadLeveling {
//         on
//         enabled
//       }
//       offgrid {
//         on
//         enabled
//       }
//     }
//   }
// `;

export const GET_HVAC_CONTROLS = gql`
  query GetHvacControls($siteId: ID!, $hvacId: Int!) {
    getHvacControls(siteId: $siteId, hvacId: $hvacId) {
      tempCooling
      tempHeating
      hysteresis
      switchOnOff
    }
  }
`;

export const GET_PEAK_SHAVING_TIME_TABLE = gql`
  query GetPeakShavingTimeTable($siteId: ID!) {
    getPeakShavingTimeTable(siteId: $siteId) {
      mon {
        hour
        minute
        energy
        ds
      }
      tue {
        hour
        minute
        energy
        ds
      }
      wed {
        hour
        minute
        energy
        ds
      }
      thu {
        hour
        minute
        energy
        ds
      }
      fri {
        hour
        minute
        energy
        ds
      }
      sat {
        hour
        minute
        energy
        ds
      }
      sun {
        hour
        minute
        energy
        ds
      }
    }
  }
`;

export const GET_CONSUMPTION_SCHEDULE_NAMES = gql`
  query GetConsumptionScheduleNames($siteId: ID!) {
    getConsumptionScheduleNames(siteId: $siteId) {
      names
    }
  }
`;

export const GET_CONSUMPTION_SCHEDULE = gql`
  query GetConsumptionSchedule($siteId: ID!, $scheduleId: String!) {
    getConsumptionSchedule(siteId: $siteId, scheduleId: $scheduleId) {
      days {
        moments {
          hour
          minute
          energy
        }
        suggestions {
          hour
          minute
          energy
        }
        history {
          hour
          minute
          lowest_energy
          low_energy
          avg_energy
          high_energy
          highest_energy
        }
      }
    }
  }
`;

export const GET_MANUAL_CONTROL_VALUES_QUERY = gql`
  query GetManualControlValues($siteId: ID!) {
    getManualControlValues(siteId: $siteId) {
      activePower
      reactivePower
    }
  }
`;

export const GET_GENERAL_SETTINGS_QUERY = gql`
  query GetGeneralSettings($siteId: ID!) {
    getGeneralSettings(siteId: $siteId) {
      socMin
      socMax
      cellMaxV
      cellMinV
      maxTempTFRoom
      maxTempTF
      converterRamp
    }
  }
`;

export const GET_PRIMARY_REGULATION_SETTINGS_QUERY = gql`
  query GetPrimaryRegulationSettings($siteId: ID!) {
    getPrimaryRegulationSettings(siteId: $siteId) {
      powerMax
      powerMin
      maxDeltaF
      minDeltaF
      posDeadBandF
      negDeadBandF
      frequency
      fpDroop
      frequencySetpoint
      truePowerSetpoint
    }
  }
`;

export const GET_SECONDARY_REGULATION_SETTINGS_QUERY = gql`
  query GetSecondaryRegulationSettings($siteId: ID!) {
    getSecondaryRegulationSettings(siteId: $siteId) {
      reactivePowerMax
      reactivePowerMin
      baseVoltage
      minVoltage
      maxVoltage
      cosPhiRC
      cosPhiRL
      secondLevel
    }
  }
`;

export const GET_POWER_FACTOR_SETTINGS_QUERY = gql`
  query GetPowerFactorSettings($siteId: ID!) {
    getPowerFactorSettings(siteId: $siteId) {
      maxReactivePower
      minReactivePower
      socMin
      cosPhiRC
      cosPhiRL
    }
  }
`;

export const GET_OFFGRID_SETTINGS_QUERY = gql`
  query GetOffgridSettings($siteId: ID!) {
    getOffgridSettings(siteId: $siteId) {
      nominalFrequency
      maxFrequency
      minFrequency
      socMax
      socMin
      droopLevel
      droopOnOff
    }
  }
`;

export const GET_LOAD_LEVELLING_SETTINGS_QUERY = gql`
  query GetLoadLevelingSettings($siteId: ID!) {
    getLoadLevelingSettings(siteId: $siteId) {
      socMaxLL
      socMinLL
      reservedPower
    }
  }
`;

export const GET_PEAK_SHAVING_SETTINGS_QUERY = gql`
  query GetPeakShavingSettings($siteId: ID!) {
    getPeakShavingSettings(siteId: $siteId) {
      socMax
      socMin
      reservedPower
      gridChargingLimit
      gridOverflowLimit
      gridCharging
      pvThrottling
    }
  }
`;

export const GET_PEAK_SHAVING_ALGORITHM_SETTINGS_QUERY = gql`
  query GetPeakShavingAlgorithmSettings($siteId: ID!) {
    getPeakShavingAlgorithmSettings(siteId: $siteId) {
      socMax
      socMin
      reservedPower
      gridOverflowLimit
      peakShavingMinOverproduction
      peakShavingMinUnderproduction
      cyclePrice
      chpEngineHourCost
      accumulationTempMin
      accumulationTempMax
    }
  }
`;

export const GET_TRADING_SETTINGS_QUERY = gql`
  query GetTradingSettings($siteId: ID!) {
    getTradingSettings(siteId: $siteId) {
      electricityOperatorFee
      electricityBuyReservedCapacityFee
      electricityBuyReservedCapacityPenalty
      electricityBuyDistributionPrice
      electricityBuySystemServicesPrice
      electricityBuyRenewableEnergyPrice
      electricitySellFixedPrice
      gasOperatorFee
      gasBuyReservedCapacityFee
      gasBuyDistributionPrice
      consumers {
        id
        title
        price_heat
        price_electro
      }
    }
  }
`;
