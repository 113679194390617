import React, { FunctionComponent } from "react";

import {
  TableRowProps,
  Th,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import { JSONArray, SharedProps } from "./types";

import ValuesChartRow from "./ValuesChartRow";
import ValuesHeatmapRow from "./ValuesHeatmapRow";
import RowValue from "./RowValue";


interface IProps extends TableRowProps, SharedProps {
  label: string;
  units?: string;
  values: JSONArray;
  withChart?: boolean;
  nowHourIndex: number;
  colors?: string[];
  multiplier?: number;
}


const RowOfValues: FunctionComponent<IProps> = ({
  label,
  units,
  values,
  cellWidth,
  cellPaddingX,
  nowHourIndex,
  colors,
  withChart = true,
  multiplier = 1,
  ...rest
}) => {

  return (
    <>
      {withChart && <ValuesChartRow {...{label, values, units, cellWidth, cellPaddingX, nowHourIndex}} />}
      {colors && <ValuesHeatmapRow {...{values, colors}} />}
      <Tr {...rest}>
        <Th pt={0} position="sticky" left={0} bg={useColorModeValue("white", "gray.950")} dangerouslySetInnerHTML={{ __html: label}} whiteSpace="nowrap"/>
        {values.map((value, index) => <RowValue isCurrentHour={nowHourIndex === index} {...{label, value, multiplier, units, cellWidth, cellPaddingX}} /> )}
      </Tr>
    </>
  )
}

export default RowOfValues;
