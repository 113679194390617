import React, { FunctionComponent, useCallback, useState, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Flex,
  Heading,
  HStack,
} from "@chakra-ui/react";

import { envVars } from "common/env";
import { useToasts } from "common/toasts";
import { useMutation } from "@apollo/client";

import {
  SiteConfig_siteConfig_exportConf, SiteConfig_siteConfig_exportConf_exports,
  ExportDataMutation, ExportDataMutationVariables,
} from "graphql/generated";
import {
  EXPORT_DATA_MUTATION,
} from "graphql/mutations";

import TimePeriodButton from "./TimePeriodButton";
import ResolutionButton from "./ResolutionButton";
import SubjectButton from "./SubjectButton";
import FormatButton from "./FormatButton";


interface IProps {
  siteId: string;
  exportConf: SiteConfig_siteConfig_exportConf;
}


const timePeriods = [
  "this week",
  "this month",
  "this quarter",
  "this year",

  "previous week",
  "previous month",
  "previous quarter",
  "previous year",

  "m1",
  "m2",
  "m3",
  "m4",
  "m5",
  "m6",
  "m7",
  "m8",
  "m9",
  "m10",
  "m11",
  "m12",

  "q1",
  "q2",
  "q3",
  "q4",
] as const;
type ExportTimePeriod = typeof timePeriods[number];

const timeResolutions = [
  "minutes",
  "15minutes",
  "hours",
  "days",
  "weeks",
  "months",
] as const;
type ExportTimeResolution = typeof timeResolutions[number];

const exportFormats = [
  "csv",
  "xls",
] as const;
type ExportFormat = typeof exportFormats[number];

const possibleSubjectPeriodResolutions: {[key: string]: {[key: string]: ExportTimeResolution[]}} = {
  "seconds": {
    "week": ["hours", "15minutes", "minutes"],
    "month": ["days", "hours", "15minutes"],
    "quarter": ["weeks", "days"],
    "year": ["months", "weeks"],
  },
  "hours": {
    "week": ["hours"],
    "month": ["days", "hours"],
    "quarter": ["weeks", "days"],
    "year": ["months", "weeks"],
  },
}


const Export: FunctionComponent<IProps> = ({
  siteId,
  exportConf,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const [ activeSubjects, setActiveSubjects ] = useState<boolean[]>(exportConf.exports.map(_ => false));
  const [ timePeriod, setTimePeriod ] = useState<ExportTimePeriod>("this week");
  const [ timeResolution, setTimeResolution ] = useState<ExportTimeResolution>("hours");
  const [ fileFormat, setFileFormat ] = useState<ExportFormat>("xls");

  const activeFrequency = useMemo(() => {
    const activeSubject = exportConf.exports.filter((_, i) => activeSubjects[i])[0];

    return activeSubject && activeSubject.frequency;
  }, [exportConf.exports, activeSubjects]);

  const toggleActiveSubject = useCallback((index: number) => {
    const newState = [...activeSubjects];

    newState[index] = !activeSubjects[index];
    setActiveSubjects(newState);
  }, [activeSubjects]);

  const [ exportData, { loading: isExporting }] = useMutation<ExportDataMutation, ExportDataMutationVariables>(EXPORT_DATA_MUTATION);

  const isSubjectPossible = useCallback((config: SiteConfig_siteConfig_exportConf_exports): boolean => {
    return !activeFrequency ? true : config.frequency === activeFrequency;
  }, [activeFrequency]);

  const isResolutionPossible = useCallback((resolution: ExportTimeResolution): boolean => {
    const timePeriodMatch = timePeriod.match(/(.+ (.+)|([mq])([0-9]+))/);

    if (!timePeriodMatch) return false;

    const timePeriodLength = timePeriodMatch[2]; // matches for example "week" in "this week"
    const timePeriodType = timePeriodMatch[3]; // matches for example "m" in "m9"

    if (!activeFrequency) return false;

    if (timePeriodLength) return possibleSubjectPeriodResolutions[activeFrequency][timePeriodLength].includes(resolution);
    if (timePeriodType === "m") return possibleSubjectPeriodResolutions[activeFrequency]["month"].includes(resolution);
    if (timePeriodType === "q") return possibleSubjectPeriodResolutions[activeFrequency]["quarter"].includes(resolution);

    return false;
  }, [timePeriod, activeFrequency]);

  const isFormatPossible = useCallback((format: ExportFormat): boolean => {
    return format !== "csv" || activeSubjects.filter(_ => _).length <= 1;
  }, [activeSubjects]);

  const y = useMemo(() => new Date().getFullYear(), []);
  const m = useMemo(() => new Date().getMonth(), []);
  const q = useMemo(() => Math.ceil((m + 1) / 3) - 1, [m]);

  return (
    <>
      <Heading size="md" mb={3}>
        {intl.formatMessage({
          id: "export__subject___title",
          defaultMessage: "Subject of export",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {exportConf.exports.map((config, i) => {
          return (
            <SubjectButton
              index={i}
              key={`exportSubject-${config.subject}${config.label}`}
              subject={config.subject}
              config={config}
              isActive={activeSubjects[i]}
              isDisabled={config.disabled || !isSubjectPossible(config)}
              onClick={() => toggleActiveSubject(i)}
            />
          )
        })}
      </Flex>
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__time_period___title",
          defaultMessage: "Time period",
        })}
      </Heading>
      {[
        timePeriods.filter(_ => _.match("^this")),
        timePeriods.filter(_ => _.match("^previous")),
        timePeriods.filter(_ => _.match("^m[0-9]")),
        timePeriods.filter(_ => _.match("^q[0-9]")),
      ].map((group) => (
        <>
          <Flex flexWrap="wrap">
            {group.map((option, i) => {
              let order, year;

              if (option.match(/^m/)) {
                order = (i < m ? 0 : 12) + m - i;
                year = order < m ? y : y - 1;
              }
              if (option.match(/^q/)) {
                order = (i < q ? 0 : 4) + q - i;
                year = order < q ? y : y - 1;
              }

              return (
                <TimePeriodButton
                  index={i}
                  key={`timePeriod-${option}`}
                  value={option}
                  year={year}
                  isActive={option === timePeriod}
                  isDisabled={false}
                  onClick={() => setTimePeriod(option)}
                  order={order}
                />
              )
            })}
          </Flex>
        </>
      ))}
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__resolution___title",
          defaultMessage: "Resolution",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {timeResolutions.map((option, i) => {
          return (
            <ResolutionButton
              index={i}
              key={`timeResolution-${option}`}
              value={option}
              isActive={option === timeResolution && isResolutionPossible(option)}
              isDisabled={!isResolutionPossible(option)}
              onClick={() => setTimeResolution(option)}
            />
          )
        })}
      </Flex>
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__format___title",
          defaultMessage: "File format",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {exportFormats.map((option, i) => {
          return (
            <FormatButton
              index={i}
              key={`timeResolution-${option}`}
              value={option}
              isActive={option === fileFormat && isFormatPossible(option)}
              isDisabled={!isFormatPossible(option)}
              onClick={() => setFileFormat(option)}
            />
          )
        })}
      </Flex>
      <HStack mt={6}>
        <Button
          size="md"
          colorScheme="yellow"
          px={10}
          flexGrow={0}
          isLoading={isExporting}
          isDisabled={!(activeSubjects.some(_ => _) && isResolutionPossible(timeResolution) && isFormatPossible(fileFormat))}
          onClick={async (event) => {
            exportData({
              variables: {
                siteId,
                exports: activeSubjects,
                timePeriod: timePeriod,
                timeResolution: timeResolution,
                fileFormat: fileFormat,
              },
            })
              .then((response) => {
                pushSuccessToast(
                  intl.formatMessage({
                    defaultMessage: "Data exported",
                    id: "export___success",
                  })
                );
                const download_url = response.data?.exportData.url;

                if (download_url) window.location.href=`${envVars.DOWNLOAD_URL}${download_url}`;
              })
              .catch((reason) => {
                pushErrorToast(
                  intl.formatMessage({
                    defaultMessage: "Data export failed ({reason})",
                    id: "export___error",
                  }, {
                    reason: String(reason),
                  })
                );
              })
              ;
          }}
        >
          {intl.formatMessage({
            id: "export___export",
            defaultMessage: "Export",
          })}
        </Button>
      </HStack>
    </>
  );
};

export default Export;
