import React, { FunctionComponent, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Image,
  Text,
} from "@chakra-ui/react";

import {
  SiteConfig_siteConfig_exportConf_exports,
} from "graphql/generated";

import BaseButton, { BaseButtonProps } from "./BaseButton";
import { deviceLabel } from "../../utils";


interface IProps extends BaseButtonProps {
  subject: string;
  config: SiteConfig_siteConfig_exportConf_exports;
}


const SubjectButton: FunctionComponent<IProps> = ({
  subject,
  config,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const exportName = useCallback((id: string): string => {
    switch (id) {
      case "furnace":
        return config.label || intl.formatMessage({
          id: "export__subject_furnace",
          defaultMessage: "Furnace",
        });
      case "factory":
        return config.label || intl.formatMessage({
          id: "export__subject_factory",
          defaultMessage: "Factory",
        });
      case "pv":
        return config.label || intl.formatMessage({
          id: "export__subject_pv",
          defaultMessage: "Photovoltaics",
        });
      case "grid":
        return config.label || intl.formatMessage({
          id: "export__subject_grid",
          defaultMessage: "Grid",
        });
      case "chp":
        return config.label || intl.formatMessage({
          id: "export__subject_chp",
          defaultMessage: "CHP",
        });
      case "battery":
        return config.label || intl.formatMessage({
          id: "export__subject_battery",
          defaultMessage: "Battery",
        });
      case "paint_shop":
        return config.label || intl.formatMessage({
          id: "export__subject_paint_shop",
          defaultMessage: "Paint Shop",
        });
      case "electro_prices":
        return config.label || intl.formatMessage({
          id: "export__subject_electro_prices",
          defaultMessage: "Electricity prices",
        });
      case "gas_prices":
        return config.label || intl.formatMessage({
          id: "export__subject_gas_prices",
          defaultMessage: "Gas prices",
        });
      default:
        return id;
    }
  }, [intl, config]);

  const imageUrl = useMemo<string>(() => {
    switch (subject) {
      case "pv": return "/images/svg/fotovoltaicka-elektrarna.svg";
      case "grid": return "/images/svg/grid.svg";
      case "battery": return "/images/svg/baterie50.svg";
      case "paint_shop": return "/images/svg/lakovna.svg";
      case "chp": return "/images/svg/kogeneracni-jednotka.svg";
      case "factory": return "/images/svg/tovarna.svg";
      case "furnace": return "/images/svg/pec.svg";
      case "electro_prices": return "/images/svg/cena-elektriny.svg";
      case "gas_prices": return "/images/svg/cena-plynu.svg";
      default: return "";
    }
  }, [subject]);

  return (
    <>
      <BaseButton
        size="xl"
        flexDirection="column"
        alignItems={"center"}
        justifyContent="flex-end"
        w={160}
        pb={3}
        {...rest}
      >
        <Box flex={1} minW={"40px"} p={3} pb={0}>
          <Image src={imageUrl} boxSize={"80px"} />
        </Box>
        <Text pt={1}>
          {exportName(subject)}
        </Text>
        {children}
      </BaseButton>
    </>
  );
};

export default SubjectButton;
