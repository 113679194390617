import React, { FunctionComponent, useState, useRef, useEffect, useMemo } from "react";
import assert from "assert";
import { debounce } from "lodash";

import {
  Code,
  Td,
  Th,
  Tr,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

import { JSONArray, JSONValue, SharedProps } from "./types";
import Chart, { Area, FunnyAxis, Line, Points } from "scenes/site/components/chart";
import { IPlotData, IDimensions } from "scenes/site/components/chart/types";
import { extent } from "d3";


interface IProps extends SharedProps {
  label: string;
  units?: string;
  values: JSONArray;
  nowHourIndex: number;
}


const ValuesChartRow: FunctionComponent<IProps> = ({
  label,
  units,
  values,
  cellWidth,
  cellPaddingX,
  nowHourIndex,
  ...rest
}) => {
  const theme = useTheme();
  const tc = theme.colors;
  const containerRef = useRef<HTMLTableCellElement>(null);
  const [windowSize, setWindowSize] = useState<IDimensions>({ width: 0, height: 0 });
  const cellAdjust = 5;

  const data = useMemo<IPlotData[]>(() => values.map((value: JSONValue, hour: number) => {
    return {
      x: hour,
      y: value !== null ? +value : null,
    }
  }), [values]);
  const dataDomain = useMemo<number[]>(() => {
    const domain = extent<number>(data.map(d => d.y as number)) as number[];
    return [domain[0] - 0.1, domain[1] + 0.1];
  }, [data]);
  const timeDomain = useMemo<[number, number]>(() => [0, values.length - 1], [values]);
  const dataLength = useMemo<number>(() => values.filter(value => value !== null).length, [values]);
  const zeroLineData = useMemo<IPlotData[]>(() => [
    {x: 0, y: 0},
    {x: dataLength - 1, y: 0},
  ], [dataLength]);
  const currentHourData = useMemo<IPlotData[]>(() => [
    {x: nowHourIndex, y: dataDomain[0]},
    {x: nowHourIndex, y: dataDomain[1]},
  ], [dataDomain, nowHourIndex]);

  // Resolve `windowSize` state and update it on window size change.
  //
  useEffect(() => {
    const containerEl = containerRef.current;
    assert(containerEl);

    const resizeWindow = () => {
      setWindowSize({
        width: containerEl.offsetWidth,
        height: containerEl.offsetHeight,
      });
    };
    resizeWindow();

    const debouncedResizeWindow = debounce(resizeWindow, 5, { leading: true, trailing: true });

    window.addEventListener('resize', debouncedResizeWindow);

    return () => {
      window.removeEventListener('resize', debouncedResizeWindow);
    }
  }, []);

  return (
    <>
      <Tr>
        <Th verticalAlign="bottom" position="sticky" left={0} bg={useColorModeValue("white", "gray.950")}>
          { units &&
            <Code fontSize={"xs"} colorScheme="yellow">{units}</Code>
          }
        </Th>
        <Td colspan={values.length} p={0} textAlign="right" ref={containerRef}>
          <Chart
            width={windowSize.width}
            height={30}
            offsetX={[cellWidth - cellPaddingX - cellAdjust, cellPaddingX + cellAdjust]}
            offsetY={[2, 2]}
            {...rest}
          >
            <Area data={data} domainX={timeDomain} domainY={dataDomain} fill={useColorModeValue(tc.gray["300"], tc.gray["700"])} />
            <Line data={data} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["700"], tc.gray["300"])} strokeWidth={1} />
            <Points data={data} domainX={timeDomain} domainY={dataDomain} fill={useColorModeValue(tc.white, tc.gray["950"])} />
            <Line data={zeroLineData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.red["600"], tc.red["400"])} />
            <Line data={currentHourData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.white, tc.gray["950"])} strokeWidth={3} opacity={0.5} />
            <Line data={currentHourData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["950"], tc.white)} strokeDasharray="1" />
            <FunnyAxis data={data} />
          </Chart>
        </Td>
      </Tr>
    </>
  )
}

export default ValuesChartRow;
