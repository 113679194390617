import React, { FunctionComponent, useState, useEffect } from "react";
import { TbCircleFilled } from "react-icons/tb";
import { IconType } from "react-icons/lib";
import { delay } from "lodash";

import {
  Box, SpaceProps, LayoutProps, PositionProps, TagProps,
} from "@chakra-ui/react";


interface IProps extends SpaceProps, LayoutProps, PositionProps, TagProps {
  data: any;
  icon?: IconType;
  hideDelay?: number;
}


const ActivityIndicator: FunctionComponent<IProps> = ({
    data,
    size = 10,
    icon = <TbCircleFilled size={size} />,
    hideDelay = 200,
    ...rest
}) => {
  const [ dataTick, setDataTick ] = useState<boolean>(false);

  useEffect(() => {
    setDataTick(true);
    delay(() => setDataTick(false), hideDelay);
  }, [data, hideDelay])

  return (
    <>
      <Box w={size} h={size} p={0} lineHeight={0} {...rest}>
      { dataTick && icon}
      </Box>
    </>
  );
};

export default ActivityIndicator;
