import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  Text,
} from "@chakra-ui/react";

import BaseButton, { BaseButtonProps } from "./BaseButton";


interface IProps extends BaseButtonProps {
  value: string;
  year?: number;
}


const TimePeriodButton: FunctionComponent<IProps> = ({
  value,
  year,
  children,
  ...rest
}) => {
  const intl = useIntl();
  const vars = useMemo(() => ({
    year: `'${year?.toString().substr(2, 2)}`,
    fullYear: year,
  }), [year]);

  const cardLabel = useCallback((id: string): string => {
    switch (id) {
      case "this week":
        return intl.formatMessage({
          id: "export__time_period__this_week",
          defaultMessage: "this week",
        });
      case "this month":
        return intl.formatMessage({
          id: "export__time_period__this_month",
          defaultMessage: "this month",
        });
      case "this quarter":
        return intl.formatMessage({
          id: "export__time_period__this_quarter",
          defaultMessage: "this quarter",
        });
      case "this year":
        return intl.formatMessage({
          id: "export__time_period__this_year",
          defaultMessage: "this year",
        });

      case "previous week":
        return intl.formatMessage({
          id: "export__time_period__previous_week",
          defaultMessage: "previous week",
        });
      case "previous month":
        return intl.formatMessage({
          id: "export__time_period__previous_month",
          defaultMessage: "previous month",
        });
      case "previous quarter":
        return intl.formatMessage({
          id: "export__time_period__previous_quarter",
          defaultMessage: "previous quarter",
        });
      case "previous year":
        return intl.formatMessage({
          id: "export__time_period__previous_year",
          defaultMessage: "previous year",
        });

      case "m1":
        return intl.formatMessage({
          id: "export__time_period__month_01",
          defaultMessage: "January {year}",
        }, vars);
      case "m2":
        return intl.formatMessage({
          id: "export__time_period__month_02",
          defaultMessage: "February {year}",
        }, vars);
      case "m3":
        return intl.formatMessage({
          id: "export__time_period__month_03",
          defaultMessage: "March {year}",
        }, vars);
      case "m4":
        return intl.formatMessage({
          id: "export__time_period__month_04",
          defaultMessage: "April {year}",
        }, vars);
      case "m5":
        return intl.formatMessage({
          id: "export__time_period__month_05",
          defaultMessage: "May {year}",
        }, vars);
      case "m6":
        return intl.formatMessage({
          id: "export__time_period__month_06",
          defaultMessage: "June {year}",
        }, vars);
      case "m7":
        return intl.formatMessage({
          id: "export__time_period__month_07",
          defaultMessage: "July {year}",
        }, vars);
      case "m8":
        return intl.formatMessage({
          id: "export__time_period__month_08",
          defaultMessage: "August {year}",
        }, vars);
      case "m9":
        return intl.formatMessage({
          id: "export__time_period__month_09",
          defaultMessage: "September {year}",
        }, vars);
      case "m10":
        return intl.formatMessage({
          id: "export__time_period__month_10",
          defaultMessage: "October {year}",
        }, vars);
      case "m11":
        return intl.formatMessage({
          id: "export__time_period__month_11",
          defaultMessage: "November {year}",
        }, vars);
      case "m12":
        return intl.formatMessage({
          id: "export__time_period__month_12",
          defaultMessage: "December {year}",
        }, vars);

      case "q1":
        return intl.formatMessage({
          id: "export__time_period__quarter_1",
          defaultMessage: "Q1 {fullYear}",
        }, vars);
      case "q2":
        return intl.formatMessage({
          id: "export__time_period__quarter_2",
          defaultMessage: "Q2 {fullYear}",
        }, vars);
      case "q3":
        return intl.formatMessage({
          id: "export__time_period__quarter_3",
          defaultMessage: "Q3 {fullYear}",
        }, vars);
      case "q4":
        return intl.formatMessage({
          id: "export__time_period__quarter_4",
          defaultMessage: "Q4 {fullYear}",
        }, vars);

      default:
        return id;
    }
  }, [intl, vars]);

  return (
    <>
      <BaseButton
        {...rest}
      >
        <Text>
          {cardLabel(value)}
        </Text>
        {children}
      </BaseButton>
    </>
  );
};

export default TimePeriodButton;
