import React, { FunctionComponent, useCallback, useMemo } from "react";

import {
  TableCellProps,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

import { JSONValue, SharedProps } from "./types";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { format } from "d3";
import { useIntl } from "react-intl";


interface IProps extends TableCellProps, SharedProps {
  value: JSONValue;
  label: string;
  units?: string;
  isCurrentHour?: boolean;
  multiplier?: number;
}


const RowValue: FunctionComponent<IProps> = ({
  value,
  label,
  units = "",
  multiplier = 1,
  cellWidth,
  cellPaddingX,
  isCurrentHour,
  ...rest
}) => {
  const intl = useIntl();

  const trueMessage = useMemo(() => intl.formatMessage({
    id: "site__trading__row_value__true",
    defaultMessage: "Yes",
  }), [intl]);
  const falseMessage = useMemo(() => intl.formatMessage({
    id: "site__trading__row_value__false",
    defaultMessage: "No",
  }), [intl]);

  const valueColor = useCallback((value: JSONValue): string | undefined => {
    if (value !== null && value < 0) return "red.500";
  }, []);

  const valueWeight = useCallback((value: JSONValue): string | undefined => {
    return isCurrentHour ? "bold" : "regular";
  }, [isCurrentHour]);

  const reasonablePrecision = useCallback((value: number): number | string => {
    const abs = Math.abs(value);

    if (abs === 0 ) return value;
    else if (abs < 10) return format(".1f")(value);
    else if (abs > 1000000) return `${ reasonablePrecision(value / 1000000)}M`;
    else if (abs > 1000) return `${ reasonablePrecision(value / 1000)}k`;
    else return Math.round(value);
  }, []);

  const tooltip = useCallback((): string => {
    if (value === null) return "";

    const lbl = label.replaceAll(/<[^>]+>/g, ""); // remove eventual HTML tags

    switch(typeof(value)) {
      case "number":  return `${lbl}: ${value * multiplier}${units}`.trim();
      case "boolean": return `${lbl}? ${value ? trueMessage : falseMessage}`;
      default:        return `${lbl}: ${value} ${units}`.trim();
    }
  }, [label, value, units, multiplier, trueMessage, falseMessage]);

  const pageBackgroundColor = useColorModeValue("white", "gray.900");
  const booleanTrueColor = useColorModeValue("enposol.500", "enposol.500");
  const booleanFalseColor = useColorModeValue("gray.200", "gray.800");

  const booleanValue = useCallback((value: boolean) => {
    return value ? <CheckIcon color="enposol.500"/> : <CloseIcon color="red.500"/>;
  }, []);

  return (
    <>
      <Td
        title={tooltip()}
        textAlign="right"
        color={valueColor(value)}
        fontWeight={valueWeight(value)}
        fontSize={"xs"}
        px={`${cellPaddingX}px`}
        py={0}
        verticalAlign={typeof(value) == "boolean" ? "middle" : "top"}
        {...rest}
      >
        { typeof(value) == "boolean" && (
          <Box width={"100%"} height={2.5} borderTopLeftRadius={3} borderRightWidth={1} borderColor={pageBackgroundColor}
            bg={value ? booleanTrueColor : booleanFalseColor}
          ></Box>
        ) }
        { typeof(value) == "number" && (
          <Box width={cellWidth} whiteSpace="pre" overflow="hidden">
            { reasonablePrecision(value * multiplier) }
          </Box>
        ) }
      </Td>
    </>
  )
}

export default RowValue;
